footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
.footer-info-element {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;
    border-radius: 12px;
    background: var(--bg);
    border: 2px solid var(--accent-1);
    box-shadow: 0 0 8px 1px rgba(61, 132, 225, 0.2);
}
.footer-info-element > span:nth-child(1) {
    color: var(--color-2);
}
.footer-value {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-end;
}
.footer-curr {
    font-size: 1em;
}
.footer-element {
    display: flex;
    gap: 40px;
}
.credits {
    padding: 0.5em 1em;
    border-radius: 12px;
    background: var(--bg);
    border: 2px solid var(--accent-1);
    box-shadow: 0 0 8px 1px rgba(61, 132, 225, 0.2);
}
.blue {
    color: var(--accent-1);
}

@media (min-width: 1280px) and (max-width: 1920px) {
    footer {
        margin: 1em;
    }
    .footer-info-element > span:nth-child(1) {
        font-size: 0.8rem;
    }
    .footer-value > span {
        font-size: 0.9rem;
    }
    .footer-info-element {
        padding: 0.4em 0.8em;
    }
    .credits {
        font-size: 0.9rem;
    }
}

@media (orientation: portrait) {
    footer {
        flex-direction: column;
        bottom: unset;
        gap: 1em;
        margin: 1em;
    }
    .footer-element {
        flex-direction: column;
        align-self: stretch;
        gap: 1em;
        text-align: center;
    }
    .footer-value {
        align-items: center;
        justify-content: center;
    }
}