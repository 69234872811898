:root {
    --bg: #28282f;
    --bg-2: #14141a;
    --color-1: #f4f2e3;
    --color-2: #adaebe;
    --color-3: white;
    --shadow-color: grey;

    --primary-1: #1b66c8;
    --accent-1: #608ceb;

    --offset: 10vw;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    min-height: 100vh;
    min-height: 100dvh;
    background: var(--bg);
    color: var(--color-1);
    max-height: 100dvh;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin: 0 var(--offset);
    gap: 2vw;
}

@media (orientation: portrait) {
    :root {
        --offset: 2vw
    }
    #root {
        flex-direction: column-reverse;
    }
    body {
        max-height: unset;
        overflow: unset;
        position: relative;
    }
}