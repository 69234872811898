.special-button-container {
    position: relative;
    padding: 0 10px;
}
.alt-btns {
    position: absolute;
    overflow: hidden;
    width: 120%;
    left: -10%;
    right: 0;
    height: 0;
    transition: height 0.3s, opacity 0.2s ease-in-out;
    opacity: 0;
}
.special-button-container:hover > .alt-btns {
    height: 200px;
    transition: height 0s, opacity 0.2s ease-in-out;
    opacity: 1;
}
.addons-container {
    transform: translateY(-130px);
    transition: 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}
.special-button-container:hover > .alt-btns > .addons-container {
    transform: translateY(0);
}
.special-button {
    color: var(--color-1);
    background: linear-gradient(to left, var(--accent-1), var(--primary-1));
    padding: 0.12em;
    border-radius: 0.3em;
    display: flex;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    box-shadow: none;
    align-self: center;
    text-align: center;
}
.special-button > span {
    border-radius: 0.3em;
    padding: 8px 20px;
    transition-duration: 0.2s;
    background: var(--bg-2);
    text-wrap: none;
}
.addons-container > .special-button {
    margin-top: 20px;
}
.special-button:hover {
    box-shadow: 0 0 13px 2px var(--accent-1);
}
.special-button:hover > span {
    background: var(--bg);
}

@media (min-width: 1280px) and (max-width: 1920px) {
    .special-button > span {
        padding: 8px 18px;
        font-size: 0.9rem;
    }
    .addons-container {
        padding: 0 1em;
    }
}