.link {
    color: var(--color-1);
    background: linear-gradient(to left, var(--accent-1), var(--primary-1));
    padding: 0.12em;
    border-radius: 0.3em;
    display: flex;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    box-shadow: none;
    margin: 0 10px;
}
.link > span {
    border-radius: 0.3em;
    padding: 8px 20px;
    transition-duration: 0.2s;
    background: var(--bg-2);
    text-align: center;
}
.link:hover {
    box-shadow: 0 0 13px 2px var(--accent-1);
}
.link:hover > span {
    background: var(--bg);
}

@media (orientation: portrait) {
    .link {
        flex: 30%;
    }
    .link > span {
        width: 100%;
        text-align: center;
    }
}
@media (min-width: 1280px) and (max-width: 1920px) {
    .link > span {
        font-size: 0.9rem;
        padding: 8px 18px;
    }
}