.article-description {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.article-description > h1 {
    font-size: 3.5rem;
    margin: 0;
}
.article-description > p {
    font-size: 1.3rem;
    line-height: 2.5rem;
    margin: 0;
    color: var(--color-2);
}
.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.text-gradient {
    background: -webkit-linear-gradient(0deg, var(--primary-1), var(--accent-1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.headline-desc {
    font-size: 2rem;
    text-shadow: -1px 0 var(--shadow-color), 0 1px var(--shadow-color), 1px 0 var(--shadow-color), 0 -1px var(--shadow-color);
    color: var(--bg);
    font-weight: bold;
    margin-top: -1.2em;
}
.links-mobile {
    display: none;
}
@media (min-width: 1280px) and (max-width: 1920px) {
    :root {
        --offset: 5vw;
    }
    #root {
        gap: 0;
    }
    .article-description > h1 {
        font-size: 2.4rem;
        margin: 0;
    }
    .article-description > p {
        font-size: 1.1rem;
        line-height: 2.2rem;
        margin-top: -1em;
    }
    .headline-desc {
        font-size: 1.5rem;
    }
    .links {
        margin-top: -1em;
        gap: 0;
    }
}
@media (orientation: portrait) {
    #root {
        gap: 0;
    }
    .article-description {
        margin-bottom: 280px;
    }
    .article-description > h1 {
        font-size: 2rem;
        margin: 0;
        text-align: center;
    }
    .article-description > p {
        font-size: 1rem;
    }
    .headline-desc {
        font-size: 1.4rem;
        text-align: center;
    }
    .links {
        flex-wrap: wrap;
        flex-direction: row;
        align-self: stretch;
        align-items: unset;
        gap: 20px 0;
    }
    .link-pc {
        display: none;
    }
    .links-mobile {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px 0;
        align-self: stretch;
    }
    .links-mobile > .link:nth-child(1) {
        flex: 100%;
    }
}