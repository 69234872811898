.grain {
    -webkit-animation: grain 1s steps(5) infinite;
    animation: grain 1s steps(5) infinite;
    background-image: url(https://grainy-gradients.vercel.app/noise.svg);
    height: 250%;
    left: -100%;
    opacity: .23;
    pointer-events: none;
    position: fixed;
    top: -100%;
    width: 250%;
    z-index: 2;
}

@keyframes grain {
    0%, 100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    10% {
        -webkit-transform: translate(-4%,-16%);
        transform: translate(-4%,-16%);
    }

    20% {
        -webkit-transform: translate(-16%,4%);
        transform: translate(-15%,4%);
    }
    30% {
        -webkit-transform: translate(7%,-15%);
        transform: translate(7%,-15%);
    }
    40% {
        -webkit-transform: translate(-5%,3%);
        transform: translate(-4%,13%);
    }
    50% {
        -webkit-transform: translate(-15%,13%);
        transform: translate(-15%,13%);
    }
    60% {
        -webkit-transform: translate(15%);
        transform: translate(13%);
    }
    70% {
        -webkit-transform: translateY(15%);
        transform: translateY(15%);
    }
    80% {
        -webkit-transform: translate(4%,13%);
        transform: translate(4%,15%);
    }
    90% {
        -webkit-transform: translate(-13%,13%);
        transform: translate(-10%,13%);
    }
}