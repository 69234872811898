.model-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
    opacity: 1;
    position: relative;
}
.model-container::after {
    position: absolute;
    content: '';
    bottom: 17%;
    height: 100px;
    width: 50%;
    margin: 0 auto;
    background: radial-gradient(#000 10%,transparent 60%);
    opacity: 0.5;
    z-index: -1;
}

@media (orientation: portrait) {
    .model-container {
        width: 100%;
        min-height: 40vh;
    }
    .model-container::after {
        height: 60px;
        bottom: 0;
    }
}