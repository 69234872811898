.bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: url("./../../media/bg.svg") 100% no-repeat;
    -webkit-mask-image: linear-gradient(#000 70%,transparent);
    mask-image: linear-gradient(#000 70%,transparent);
}
@media (orientation: portrait) {
    .bg {
        top: unset;
        height: 50vh;
    }
}